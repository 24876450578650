import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, Sanitizer } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Network } from '@ionic-native/network/ngx';
import { LoadingController, MenuController, NavController } from '@ionic/angular'; //import MenuController to access toggle() method.
import { async } from 'rxjs/internal/scheduler/async';

@Component({
  selector: 'app-social',
  templateUrl: './social.page.html',
  styleUrls: ['./social.page.scss'],
})
export class SocialPage implements OnInit {

  constructor(
    public menuCtrl: MenuController,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    public navCtrl: NavController,
    public httpClient: HttpClient,
    public network: Network,
    private loadingCtrl: LoadingController,
  ) { }
  profiledata: any;
  ifrmsrc = this.sanitizer.bypassSecurityTrustResourceUrl('');;
  dangerousUrl: any;

  hideiframe = true;
  hideimg = true;
  thepage = "";
  loading: any;
  subscriptionChangeNetwork: any;
  async ionViewWillEnter() {
    //this.loading = await this.loadingCtrl.create({ message: 'تحميل ...' })
    //this.loading.present();
    this.test();
  }

  t() {
    var mythis=this;
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200) {
          console.log('cnx mrigla');
          mythis.hideiframe = false;
          mythis.hideimg = true;
      }
      if (this.readyState == 4 && this.status != 200) {
        console.log('cnx cv pas');
        mythis.hideiframe = true;
        mythis.hideimg = false;
        mythis.getprofiledetails();
      }

    };
    xhttp.open("GET", "https://test.agritun.com/test.php?a="+ Math.floor(Math.random() * 10000), true);
    xhttp.send();



    console.log('hello');
  }
  ngOnInit() {
    //this.test();

  }
  async test() {
    this.network.onChange().subscribe(() => {
      this.navCtrl.navigateRoot('/welcome');
    }, (error) => {
      this.navCtrl.navigateRoot('/welcome');

    });

    if (!localStorage.getItem('token')) {
      this.navCtrl.navigateRoot('/welcome');
    }
    const headers = new HttpHeaders()
      .set('X-API-KEY', '42481E32E6E161B5906A282FBCE9E9B1')
      .set('X-token', localStorage.getItem('token'))
    return this.httpClient
      .get('https://v3.agritun.com:443/api/user/profile', { headers: headers })
      .subscribe(data => {
        this.loadifrm();
        return true;

      }, error => {
        if (error.status === 406) {

          this.navCtrl.navigateRoot('/welcome');
          return false;
        }
        else {
          this.navCtrl.navigateRoot('/welcome');

          return false;
        }
      });
  }

  public loadifrm() {
    if (this.route.snapshot.paramMap.get('page')) {
      this.thepage = this.route.snapshot.paramMap.get('page');
    }

    this.profiledata = JSON.parse(localStorage.getItem('profile'));
    console.log(this.profiledata);

    this.dangerousUrl = 'https://test.agritun.com/mobile/index.php?agritunid=' + this.profiledata.id + '&fullname=' + this.profiledata.full_name + '&tel=' + this.profiledata.username + '&page=' + this.thepage + '&number=' + Math.floor(Math.random() * 100);

    this.ifrmsrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerousUrl)
    this.getprofiledetails();
   
  }


  toggleMenu() {
    this.menuCtrl.toggle(); //Add this method to your button click function
  }


  getprofiledetails() {

    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200) {
        var resp = JSON.parse(this.responseText);
        localStorage.setItem('user_firstname', resp.user_firstname);
        localStorage.setItem('user_lastname', resp.user_lastname);
        localStorage.setItem('user_picture', resp.user_picture);
      }


    };
    xhttp.open("GET", "https://test.agritun.com/api_profile.php?agritun_id=" + this.profiledata.id + '&number=' + Math.floor(Math.random() * 100), true);
    xhttp.send();
  }


}
