import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'welcome',
    pathMatch: 'full'
  },
  {
    path: 'welcome',
    loadChildren: () => import('./welcome/welcome.module').then( m => m.WelcomePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'parcels',
    loadChildren: () => import('./parcels/parcels.module').then( m => m.ParcelsPageModule)
  },
  {
    path: 'add-parcel',
    loadChildren: () => import('./add-parcel/add-parcel.module').then( m => m.AddParcelPageModule)
  },
  {
    path: 'equipe',
    loadChildren: () => import('./equipe/equipe.module').then( m => m.EquipePageModule)
  },
  {
    path: 'list-equipe',
    loadChildren: () => import('./list-equipe/list-equipe.module').then( m => m.ListEquipePageModule)
  },
  {
    path: 'recolte/',
    loadChildren: () => import('./recolte/recolte.module').then( m => m.RecoltePageModule)
  },
  {
    path: 'list-recolt/:parcelId',
    loadChildren: () => import('./list-recolt/list-recolt.module').then( m => m.ListRecoltPageModule)
  },
  {
    path: 'transporteur',
    loadChildren: () => import('./transporteur/transporteur.module').then( m => m.TransporteurPageModule)
  },
  {
    path: 'list-transporteur',
    loadChildren: () => import('./list-transporteur/list-transporteur.module').then( m => m.ListTransporteurPageModule)
  },
  {
    path: 'transport',
    loadChildren: () => import('./transport/transport.module').then( m => m.TransportPageModule)
  },
  {
    path: 'list-transport',
    loadChildren: () => import('./list-transport/list-transport.module').then( m => m.ListTransportPageModule)
  },
  {
    path: 'masra',
    loadChildren: () => import('./masra/masra.module').then( m => m.MasraPageModule)
  },
  {
    path: 'list-masra',
    loadChildren: () => import('./list-masra/list-masra.module').then( m => m.ListMasraPageModule)
  },
  {
    path: 'masra-entrant',
    loadChildren: () => import('./masra-entrant/masra-entrant.module').then( m => m.MasraEntrantPageModule)
  },
  {
    path: 'list-masra-entrant/:masraId',
    loadChildren: () => import('./list-masra-entrant/list-masra-entrant.module').then( m => m.ListMasraEntrantPageModule)
  },
  {
    path: 'masra-sortant',
    loadChildren: () => import('./masra-sortant/masra-sortant.module').then( m => m.MasraSortantPageModule)
  },
  {
    path: 'list-masra-sortant/:masraId',
    loadChildren: () => import('./list-masra-sortant/list-masra-sortant.module').then( m => m.ListMasraSortantPageModule)
  },
  {
    path: 'vente',
    loadChildren: () => import('./vente/vente.module').then( m => m.VentePageModule)
  },
  {
    path: 'list-vente',
    loadChildren: () => import('./list-vente/list-vente.module').then( m => m.ListVentePageModule)
  },
  {
    path: 'achat',
    loadChildren: () => import('./achat/achat.module').then( m => m.AchatPageModule)
  },
  {
    path: 'list-achat',
    loadChildren: () => import('./list-achat/list-achat.module').then( m => m.ListAchatPageModule)
  },
  {
    path: 'paiment-autre',
    loadChildren: () => import('./paiment-autre/paiment-autre.module').then( m => m.PaimentAutrePageModule)
  },
  {
    path: 'list-paiment-autre',
    loadChildren: () => import('./list-paiment-autre/list-paiment-autre.module').then( m => m.ListPaimentAutrePageModule)
  },
  {
    path: 'list-paiment-equipe/:equipeId',
    loadChildren: () => import('./list-paiment-equipe/list-paiment-equipe.module').then( m => m.ListPaimentEquipePageModule)
  },
  {
    path: 'paiment-equipe',
    loadChildren: () => import('./paiment-equipe/paiment-equipe.module').then( m => m.PaimentEquipePageModule)
  },
  {
    path: 'paiment-transporteur',
    loadChildren: () => import('./paiment-transporteur/paiment-transporteur.module').then( m => m.PaimentTransporteurPageModule)
  },
  {
    path: 'list-paiment-transporteur/:transporteurId',
    loadChildren: () => import('./list-paiment-transporteur/list-paiment-transporteur.module').then( m => m.ListPaimentTransporteurPageModule)
  },
  {
    path: 'paiment-masra',
    loadChildren: () => import('./paiment-masra/paiment-masra.module').then( m => m.PaimentMasraPageModule)
  },
  {
    path: 'list-paiment-masra/:masraId',
    loadChildren: () => import('./list-paiment-masra/list-paiment-masra.module').then( m => m.ListPaimentMasraPageModule)
  },
  {
    path: 'testchart',
    loadChildren: () => import('./testchart/testchart.module').then( m => m.TestchartPageModule)
  },
  {
    path: 'quick-add',
    loadChildren: () => import('./quick-add/quick-add.module').then( m => m.QuickAddPageModule)
  },{
    path: '',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'parcel-stats/:parcelId',
    loadChildren: () => import('./parcel-stats/parcel-stats.module').then( m => m.ParcelStatsPageModule)
  },
  {
    path: 'social',
    loadChildren: () => import('./social/social.module').then( m => m.SocialPageModule)
  },





];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules,onSameUrlNavigation: 'reload'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
