import { Component } from '@angular/core';
import { MenuController } from '@ionic/angular'; 
import {SocialPage} from '../app/social/social.page'
import { Platform,NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { Network } from '@ionic-native/network/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  activePageTitle = 'Dashboard';
  Pages = [
    {
      title: 'Dashboard',
      url: 'dashboard',
      icon: 'albums'
    },
    {
      title: 'Login',
      url: '/login',
      icon: 'person'
    },
  
  ];

  profile  = [
    {
    id:"1",
    email:"mharsikarim@gmail.com",
    username:"mharsikarim",
    full_name:"Karim MHARSI",
    avatar:"20210710022227-profile.jpg",
    banned:"0"
  }
    ];
    activeIndex : Number;
    i: Number;
    sub:string;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public httpClient: HttpClient,
    private router: Router,
    private firebaseX: FirebaseX,
    private menu: MenuController,
    private social: SocialPage,
    public navCtrl: NavController,
    public network:Network,

  ) {
    this.initializeApp();
  }
  isApp=false;
  user_picture='';
  user_firstname='';
  user_lastname='';
  public getprof() {
    this.sub=localStorage.getItem('sub');
    if (localStorage.getItem('profile')!=null){
      this.profile = JSON.parse(localStorage.getItem('profile'));
      this.user_picture=localStorage.getItem('user_picture');
      this.user_firstname=localStorage.getItem('user_firstname');
      if(localStorage.getItem('user_lastname')!='null'){
        this.user_lastname=localStorage.getItem('user_lastname');
      }else{
        this.user_lastname='';
      }

    }
  }
  public logout() {
    const l=localStorage.getItem('sub');
    const ll=localStorage.getItem('subname');
    localStorage.clear();
    localStorage.setItem('sub',l);
    if(ll!="app" && ll!="null"){      
    localStorage.setItem('subname',ll);
  }
    this.router.navigate(['/login/'])
  }


  gotopage(url){
    this.router.navigate([`/social`, { page: url}]);
    this.social.loadifrm();
    this.menu.close();
    //document.getElementById('myiframe').setAttribute('src','https://google.com');
  }
  
  initializeApp() {
    this.network.onChange().subscribe(() => {
      this.navCtrl.navigateRoot('/welcome');
    }, (error) => {
      this.navCtrl.navigateRoot('/welcome');

    });


    //firebase init
    this.firebaseX.getToken()
  .then(token => console.log(`The token is ${token}`)) // save the token server-side and use it to push notifications to this device
  .catch(error => console.error('Error getting token', error));

this.firebaseX.onMessageReceived()
  .subscribe(data => console.log(`User opened a notification ${data}`));

this.firebaseX.onTokenRefresh()
  .subscribe((token: string) => console.log(`Got a new token ${token}`));





    this.platform.ready().then(() => {
      
      replaceUrl: false;
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.getprof();
      
    });
  }
}
